import React, { useEffect, useState, useRef } from 'react';
import './PopUp.css';

const PopUp = () => {
  const [book, setBook] = useState(null);
  const [isVisible, setIsVisible] = useState(false);
  const ws = useRef(null);
  const timeoutRef = useRef(null);

  useEffect(() => {
    ws.current = new WebSocket("ws://" + process.env.REACT_APP_BACKEND_URL + '/notifications');

    ws.current.onopen = () => {
      console.log('WebSocket connection established.');
    };

    ws.current.onmessage = (event) => {
      const data = JSON.parse(event.data);
      if (data.title) {
        setBook({ title: data.title });
        setIsVisible(true);

        clearTimeout(timeoutRef.current);
        timeoutRef.current = setTimeout(() => {
          setIsVisible(false);
        }, 5000);
      }
    };

    ws.current.onclose = () => {
    	console.log("Disconnected");
    }

    return () => {
      ws.current.close();
      clearTimeout(timeoutRef.current);
    };
  }, []);

  const handleClose = () => {
    setIsVisible(false);
  };

  return (
    <>
      {isVisible && book && (
        <div className={`notification-popup ${!isVisible ? 'fade-out' : ''}`}>
          <p>New Book: {book.title}</p>
          <button onClick={handleClose}>Close</button>
        </div>
      )}
    </>
  );
};

export default PopUp;
