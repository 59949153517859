import React, { useState, useEffect } from 'react';
import './App.css';
import PopUp from './components/PopUp';

const API_URL = "http://" + process.env.REACT_APP_BACKEND_URL + "/api/books";

function App() {
  const [searchTerm, setSearchTerm] = useState('');
  const [books, setBooks] = useState([]);
  const [loading, setLoading] = useState(false);
  const [view, setView] = useState('search');
  const [selectedBook, setSelectedBook] = useState(null);

  useEffect(() => {
    const fetchBooks = async () => {
      setLoading(true);
      try {
        const url = searchTerm ? `${API_URL}/search/${searchTerm}` : API_URL;
        const response = await fetch(url);
        if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`);
        const data = await response.json();
        setBooks(data);
      } catch (error) {
        console.error("Error fetching books:", error);
      } finally {
        setLoading(false);
      }
    };
  
    if (view === 'search') {
      fetchBooks();
    }
  }, [searchTerm, view]);

  const handleDelete = async (id) => {
    try {
      const response = await fetch(`${API_URL}/${id}`, {
        method: 'DELETE',
      });
      if (!response.ok) throw new Error(`Failed to delete book. Status: ${response.status}`);
      setBooks((prevBooks) => prevBooks.filter((book) => book.book_id !== id));
    } catch (error) {
      console.error("Error deleting book:", error);
    }
  };

  const handleEdit = (book) => {
    setSelectedBook(book);
    setView('update');
  };

  return (
    <div className="App">
      <PopUp />
      <h1>Library Management System</h1>
      <nav>
        <button onClick={() => { setView('search'); setSelectedBook(null); }}>Search Books</button>
        <button onClick={() => { setView('add'); setSelectedBook(null); }}>Add Book</button>
      </nav>

      {view === 'search' && (
        <>
          <SearchBar searchTerm={searchTerm} onSearchChange={setSearchTerm} />
          {loading ? <LoadingMessage /> : <BookList books={books} onDelete={handleDelete} onEdit={handleEdit} />}
        </>
      )}

      {view === 'add' && <AddBookForm />}

      {view === 'update' && selectedBook && (
        <UpdateBookForm book={selectedBook} onBookUpdated={() => setView('search')} />
      )}
      
    </div>
  );
}

const SearchBar = ({ searchTerm, onSearchChange }) => (
  <div className="search-bar">
    <input
      type="text"
      placeholder="Search for a book..."
      value={searchTerm}
      onChange={(e) => onSearchChange(e.target.value)}
    />
  </div>
);

const LoadingMessage = () => <p>Loading books...</p>;

const BookList = ({ books, onDelete, onEdit }) => (
  <div className="book-list">
    {books.length > 0 ? (
      books.map((book) => (
        <BookItem key={book.id} book={book} onDelete={onDelete} onEdit={onEdit} />
      ))
    ) : (
      <p>No books found</p>
    )}
  </div>
);

const BookItem = ({ book, onDelete, onEdit }) => (
  <div className="book-item">
    <h3>{book.title}</h3>
    <p>Book ID: {book.book_id}</p>
    {book.author ? (
      <p>Author: {book.author.name}</p>
    ) : (
      <p>Author: {'Unknown'}</p>
    )}
    <p>Published Year: {book.published_year}</p>
    <button onClick={() => onEdit(book)}>Update</button>
    <button onClick={() => onDelete(book.book_id)}>Delete</button>
  </div>
);

const AddBookForm = () => {
  const [title, setTitle] = useState('');
  const [authorId, setAuthorId] = useState('');
  const [publishedYear, setPublishedYear] = useState('');
  const [status, setStatus] = useState('');

  const handleAddBook = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(API_URL, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ title, author: authorId, published_year: publishedYear })
      });
      if (!response.ok) throw new Error(`Error! Status: ${response.status}`);
      setStatus('Book added successfully!');
      setTitle('');
      setAuthorId('');
      setPublishedYear('');
    } catch (error) {
      console.error("Error adding book:", error);
      setStatus('Failed to add book.');
    }
  };

  return (
    <div className="add-book-form">
      <h2>Add a New Book</h2>
      <form onSubmit={handleAddBook}>
        <input type="text" placeholder="Title" value={title} onChange={(e) => setTitle(e.target.value)} required />
        <input type="text" placeholder="Author ID" value={authorId} onChange={(e) => setAuthorId(e.target.value)} required />
        <input type="number" placeholder="Published Year" value={publishedYear} onChange={(e) => setPublishedYear(e.target.value)} required />
        <button type="submit">Add Book</button>
      </form>
      {status && <p>{status}</p>}
    </div>
  );
};

const UpdateBookForm = ({ book, onBookUpdated }) => {
  const [title, setTitle] = useState(book.title);
  const [authorId, setAuthorId] = useState(book.author.id);
  const [publishedYear, setPublishedYear] = useState(book.published_year);
  const [status, setStatus] = useState('');

  const handleUpdateBook = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`${API_URL}/${book.book_id}`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ title, author_id:authorId, published_year: publishedYear })
      });
      
      if (!response.ok) throw new Error(`Error! Status: ${response.status}`);
      setStatus('Book updated successfully!');
      onBookUpdated();
    } catch (error) {
      console.error("Error updating book:", error);
      setStatus('Failed to update book.');
    }
  };

  return (
    <div className="update-book-form">
      <h2>Update Book</h2>
      <form onSubmit={handleUpdateBook}>
        <input type="text" placeholder="Title" value={title} onChange={(e) => setTitle(e.target.value)} required />
        <input type="text" placeholder="Author ID" value={authorId} onChange={(e) => setAuthorId(e.target.value)} required />
        <input type="number" placeholder="Published Year" value={publishedYear} onChange={(e) => setPublishedYear(e.target.value)} required />
        <button type="submit">Update Book</button>
      </form>
      {status && <p>{status}</p>}
    </div>
  );
};

export default App;
